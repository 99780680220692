import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/integrations-slack.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const PagerDutyIntegrationPage = ({ data }) => (
  <Layout>
    <SEO
      title="Connect PagerDuty to your Odown monitors"
      description="Receive real-time alerts from Odown to your PagerDuty account."
      pathname={`/integrations/pagerduty/`}
      image={{
        src: "/general-image-og--odown_integration_PagerDuty.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid}/>
    <Description />
    <CombinedSections />
    <CombinedSections2 />
    <Howtouse />
    <SuperchargeYourIncidentResponseSection />
    <BuiltforTeamsofAllSizes />
    <SecureandReliable />
    <TryOdownSection />
    <ClosingSection />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = ({ imageData }) => {
    const [getStartedButton, setGetStartedButton] = useState({
      name: "Get started for free",
      link: "https://app.odown.io/signup",
    });
  
    return (
      <div className="u-features slack-hero">
        <div className="flex-container">
          <div className="text-section">
            <h1 className="custom-heading-title">
            Stay Ahead of Odowntime with Odown's PagerDuty Integration
            </h1>
            <div className="u-features__heading-btn center-button">
              <OutboundLink
                className="custom-btn custom-btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blank"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
          <div className="image-section">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={imageData}
              alt="Integration - PagerDuty"
            />
          </div>
        </div>
      </div>
    );
  };  
const Description = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <p className="u-main-feature_tc-desc2">
    Website availability is an upgraded system that has more 
    importance than the normal monitoring systems that are used for 
    many different tasks. As of now Odown's PagerDuty Integration can 
    do most of the work for example it can give high quality monitoring, 
    with the help of different applications you can do various tasks like 
    real-time alerts, on-call schedules management, and also handle outages 
    faster than before.    
       </p>
     </div>
  </section>
)

const CombinedSections = ({ imageData }) => (
    <div className="sections-container">
        <h2 className="centered-heading">Why Connect Odown with PagerDuty?</h2>
        <div className="real-time-section">
            <RealTimeNotificationSection imageData={imageData} />
        </div>
        <div className="streamlined-section">
            <StreamlinedIncidentManagementSection imageData={imageData} />
        </div>
    </div>
);

  const RealTimeNotificationSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Real-Time Notifications</h3>
              <p className="u-main-feature_tc-desc">
              Monitoring systems in current time increase efficiency of Odowntime detection. 
              Today, such systems need proper alerts, and initially they need instant flagging 
              of issues and now multichannel delivery too. These systems convey many things such 
              as, spot outages instantly and also natural elements like reduced MTTR which 
              symbolize good monitoring.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const StreamlinedIncidentManagementSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Streamlined Incident Management</h3>
              <p className="u-main-feature_tc-desc">
              Wrong incident handling is very problematic during Odowntime. Different methods 
              are used and with the help of proper tools they make very organized approach 
              that is highly beneficial for quick resolution. Also, teams are benefited from 
              this as they take centralized activity and use proper ownership to complete incident management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const CombinedSections2 = () => (
    <div className="sections-container">
        <div className="real-time-section">
            <CustomizableForYourWorkflowSection />
        </div>
        <div className="streamlined-section">
            <A360ViewOfPerformanceSection />
        </div>
    </div>
);

const CustomizableForYourWorkflowSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Customizable for Your Workflow</h3>
              <p className="u-main-feature_tc-desc">
              Long time without proper configuration can cause alert management problems. 
              Today you find proper routing like severity-based and time-based and as such 
              can come in handy in the current monitoring culture. This system according to 
              experts is extremely crucial to incident management and this proves just how 
              important proper configuration is.
              </p>
              <div className="u-main-feature_tc-desc">
              These configurations many depicts different patterns and beautiful workflows 
              containing stories of the teams and about people's schedules and responsibilities:
               <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Granular Severity Levels</strong></p>
                <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Flexible Schedules </strong> </p>
                <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Team-Specific Routing </strong> 
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const A360ViewOfPerformanceSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <p className="u-main-feature_tc-desc">
              A 360° View of Your Website's Performance monitoring is a good technique 
              that has more benefits than the normal monitoring that are used for website 
              tracking. As of now continuous tracking can do most of the work for example 
              a good monitoring system can track uptime and response times, with the help 
              of different strategies you can do various tasks like early warnings, 
              geo-specific tracking, and also handle maintenance windows efficiently.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Howtouse = () => {
    return (
      <section className="u-main-feature integrations-slack-section" style={{ padding: '30px 20px' }}>
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="content-title1">Simple Setup, Quick Results</h2>
              <p className="u-main-feature_tc-desc2">
              Odown to PagerDuty connection is a good technique that has more efficiency 
              than the normal techniques that are used for many different alerts. At present 
              this setup can do most of the work in few minutes for example giving real-time 
              alerts that make your team very informed.
              </p>
    
              <p className="use-desc2">
              Access Integrations: 
              Sign in to Odown dashboard and go to "Integrations" section.
              </p>
              <p className="use-desc2">
              Select PagerDuty: 
              Follow steps to connect Odown with PagerDuty account.              
              </p>
              <p className="use-desc2">
              Configure Your Alerts:
              Select monitors for alerts and set severity levels.              
              </p>
              <p className="use-desc2">
              Start Monitoring: 
              After integration, Odown will send alerts to PagerDuty when problems happen.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const SuperchargeYourIncidentResponseSection = () => {
    const features = [
      {
        title: "Fast, Targeted Escalations",
        description: "Right people get notifications first, and automatic escalation happens if needed.",
      },
      {
        title: "Consolidated Timeline",
        description: "All troubleshooting information stays in one place.",
      },
      {
        title: "Accelerated Resolutions",
        description: "Problem fixing becomes faster which helps site reliability and user happiness.",
      },
    ];
  
    return (
      <section className="u-advanced-features" style={{ padding: '30px 20px' }}>
        <div className="container">
          <h2 className="content-title1">Supercharge Your Incident Response</h2>
          <div className="u-advanced-features__items">
          <p className="u-main-feature_tc-desc2">
          Odown monitoring with PagerDuty management has more benefits that can improve website problem handling:
       </p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <div className="u-a-features__item-wrapper">
                    <div className="u-a-features__item--title">
                      {feature.title}
                    </div>
                    <div className="u-a-features__item--desc">
                      {feature.description}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  };
 
  const BuiltforTeamsofAllSizes = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Perfect for Teams of All Sizes</h2>
    <p className="u-main-feature_tc-desc2">
    Teams of different sizes can use Odown's PagerDuty Integration that has more flexibility than other systems: 
    </p>
                <div className="use-desc2">
                Smaller Teams : 
                 Quick setup and instant alerts without complex systems.
                </div>
                <p className="use-desc2">
                Larger Enterprises : 
                 Advanced schedules, multiple teams, and custom paths for big systems.
                </p>
     </div>
  </section>
)


const SecureandReliable = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Secure & Reliable</h2>
    <p className="u-main-feature_tc-desc2">
    Security in Odown has more importance that protects data with good encryption. 
    These security measures make integration very safe by giving you varied controls
     which help to manage access.    
       </p>
     </div>
  </section>
)


const TryOdownSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}> 
    <div className="container">
    <h2 className="centered-heading">Try Odown's PagerDuty Integration Today</h2>
    <p className="u-main-feature_tc-desc2">
    Website problems need quick response that can help business success. Presently 
    Odown with PagerDuty can do most of the work for example giving instant alerts, 
    managing problems, and checking performance.
       </p>
     </div>
  </section>
)

const ClosingSection = () => (
    <div className="sections-container-closing" style={{ padding: '30px 20px' }}>
        <h2 className="centered-heading">
        Need Help or Have Questions?
        </h2>
        <p className="u-main-feature_tc-desc2">
        Support team is ready for help that has more dedication than normal support. 
        Today, such support can help with setup, and initially they help with best 
        practices and now problem-solving too.
       </p>
    </div>
);


export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "pagerduty_icon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    
  }
`

export default PagerDutyIntegrationPage